<template>
  <div :class="items.length === 0 ? 'list-no-results' : 'list'">
    <div class="section-title">
      <h2 class="label">People ({{ items.length }})</h2>

      <ion-spinner v-if="loading" color="primary" name="lines" class="spinner"></ion-spinner>
    </div>

    <span v-if="items.length === 0 && !loading">No results found</span>

    <template v-else>
      <PersonItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        @click="onClick"
      />
    </template>
  </div>
</template>

<script>
  import PersonItem from '@/components/search/lists/PeopleList/partials/PersonItem';
  import { IonSpinner } from '@ionic/vue';

  export default {
    name: 'PeopleList',

    components: {
      PersonItem,
      IonSpinner
    },

    props: {
      items: {
        type: Array,
        default: () => []
      },
      loading: {type: Boolean, default: false}
    },

    emits: ['select'],

    methods: {
      onClick(item) {
        this.$emit('select', item);
      }
    }
  };
</script>

<style scoped lang="scss">
  .list {
    color: #181f36 !important;
    text-align: left;
    .section-title {
      font-size: 18px;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 32px;

      h2{
        font-weight:600;
        font-size: 18px;
      }

      .label {
        margin-top: 10px;
      }

      .spinner {
        margin-left: 10px;
      }
    }
  }
  .list-no-results{
      color: #181f36 !important;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: left;
      width: 750px;

    .section-title {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 16px;
       h2{
        font-weight:600;
        font-size: 18px;
      }
      .label {
        margin-top: 10px;
      }

      .spinner {
        margin-left: 10px;
      }
    }
  }
 @media (orientation: portrait) { 
  .list-no-results{
      padding-left: 20px;
      
      }
 }
</style>
