<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 2L10.8841 7.72044C10.9583 7.79455 11 7.89512 11 8C11 8.10488 10.9583 8.20545 10.8841 8.27956L5 14" stroke="#4064C0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
    name:"Arrow"
}
</script>