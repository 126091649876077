<template>
  <div class="row" @click="$emit('click', item)" :data-cy="item.id">
    <div class="info">
      <FamilyPic />
      <h3>{{ item ? item.name : '' }}</h3>
      <span>{{ members }},..</span>
    </div>
    <Arrow />
  </div>
</template>

<script>
  import FamilyPic from '@/components/shared/icons/FamilyPic';
  import Arrow from '@/components/shared/icons/Arrow';

  export default {
    name: 'HouseholdItem',

    components: {
      FamilyPic,
      Arrow,
    },

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    computed: {
      members() {
        return this.item.members.slice(0, 3).map(member => member.first_name).join(', ');
      }
    }
  };
</script>

<style scoped lang="scss">
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #181f36;
    width: 750px;
    height: 86px;
    left: 0px;
    top: 0px;
    padding-inline: 24px;
    background: #ffffff;
    margin-bottom: 20px;
    border: 1px solid rgba(80, 95, 115, 0.2);
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;

    .info {
      display: flex;
      align-items: center;

      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0%;
        margin-inline: 16px;
      }
      span {
        color: #181f36;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
      }
    }
  }
    @media only screen and (orientation: portrait) {
  .row {
   width: 710px;
  }
}
</style>
