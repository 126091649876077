<template>
  <div class="add-person">
    <h1>Add new person</h1>
    <Form ref="personForm" v-slot="{ meta }">
      <Field
        v-slot="{ errors, field }"
        v-model="form.first_name"
        as="div"
        vid="first_name"
        name="First Name"
        rules="required|min:1"
      >
        <Input
          v-bind="field"
          label="First Name"
          type="text"
          placeholder="Your name"
          cy-name="input-first-name"
          :required="true"
          :error="errors[0] || null"
          :capitalizeWords="true"
        />
      </Field>

      <Field
        v-slot="{ errors, field }"
        v-model="form.last_name"
        as="div"
        vid="last_name"
        name="Last Name"
        rules="required|min:1"
      >
        <Input
          v-bind="field"
          label="Last name"
          type="text"
          placeholder="Your last name"
          cy-name="input-last-name"
          :required="true"
          :error="errors[0] || null"
          :capitalizeWords="true"
        />
      </Field>

      <Field
        v-slot="{ field }"
        v-model="form.gender"
        as="div"
        vid="gender"
        name="Gender"
      >
        <SelectInput
          v-bind="field"
          :options="genderOptions"
          placeholder="Not specified"
          label="Gender"
          cy-name="input-gender"
        />
      </Field>

      <Field
        v-slot="{ errors, field }"
        v-model="form.date_of_birth"
        as="div"
        vid="date_of_birth"
        name="Date of Birth"
      >
        <DatePicker
          v-bind="field"
          label="Date of Birth"
          cy-name="input-date-of-birth"
          :error="errors[0] || null"
        />
      </Field>

      <Field
        v-slot="{ field }"
        v-model="household"
        as="div"
        vid="household"
        name="Household"
      >
        <SearchableHouseholdInput
          v-bind="field"
          cy-name="input-search-household"
        />
      </Field>

      <div>
        <Button
          text="Add Person"
          button-type="submit"
          :loading="form.processing"
          :disabled="form.processing || !meta.valid"
          data-cy="person-submit-button"
          @click="createPerson"
        />
      </div>
    </Form>
  </div>
</template>

<script>
  import { Form, Field } from 'vee-validate';
  import { mapActions, mapState } from 'vuex';

  import Input from '@/components/shared/inputs/Input.vue';
  import SelectInput from '@/components/shared/inputs/SelectInput.vue';
  import DatePicker from '@/components/shared/inputs/DatePicker.vue';
  import SearchableHouseholdInput from '@/components/shared/inputs/SearchableHouseholdInput.vue';
  import Button from '@/components/shared/buttons/Button.vue';

  import capitalizeWords from '@/utils/functions/capitalizeWords';

  export default {
    name: 'NewPersonForm',

    components: {
      Input,
      SelectInput,
      DatePicker,
      SearchableHouseholdInput,
      Button,
      Form,
      Field,
    },

    emits: ['created'],

    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          gender: '',
          date_of_birth: null,
          processing: false,
        },
        genderOptions: ['Female', 'Male'],
        household: null,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),
    },

    methods: {
      ...mapActions({
        storePerson: 'people/store',
        storeHousehold: 'households/store',
        addMemberToHousehold: 'households/addMember',
      }),

      /**
       * Create new person
       */
      async createPerson() {
        this.form.processing = true;

        this.form.first_name = capitalizeWords(this.form.first_name)
        this.form.last_name = capitalizeWords(this.form.last_name);

        try {
          const person = await this.storePerson({
            orgId: this.user.currentOrganizationId,
            form: this.form,
          });

          if (this.household && this.household.id) {
            await this.attachPersonToHousehold(this.household, person);
          } else {
            await this.createHousehold(person);
          }

          this.$emit('created');
        } catch (ex){
          this.showToast('Something went wrong', 'danger');
        } finally {
          this.form.processing = false;
        }
      },

      /**
       * Create household
       */
      createHousehold(person) {
        return this.storeHousehold({
          organizationId: this.user.currentOrganizationId,
          form: {
            name: this.household || `${person.first_name} ${person.last_name}`,
            members: [
              {
                ...person,
                primary: true,
              },
            ],
          },
        });
      },

      /**
       * Attach a person to a household
       */
      attachPersonToHousehold(household, person, primary) {
        if (!household) {
          return Promise.resolve(true);
        }

        return this.addMemberToHousehold({
          organizationId: this.user.currentOrganizationId,
          household,
          form: {
            id: person.id,
            primary: !!primary || false,
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px; 
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; 
}

::-webkit-scrollbar-thumb {
  border: 1px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 3em;
  background-color: grey;
  opacity: 50;
  height: 50%;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
  .add-person {
    h1 {
      font-size: 28px;
      line-height: 1;
      margin-top: 0;
    }

    form {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 512px;
      overflow: auto;

      > div {
        margin-top: 20px;
      }
    }
  }
</style>
