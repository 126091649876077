<template>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <div>
        <h3>Not what you were looking for? Try the following:</h3>
        <Button variant="dark-blue" text="Add a new person" textColor="white" @click="onNewPersonClick" data-cy="new-person-button"/>

        <NewPerson ref="new-person" @created="$emit('created')" />
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import Button from '@/components/shared/buttons/Button.vue';
import NewPerson from '@/components/people/NewPerson';

import { IonFooter, IonToolbar } from '@ionic/vue';

export default {
    name:"SearchFooter",

    components: {
        Button,
        NewPerson,
        IonFooter,
        IonToolbar
    },

    methods: {
      onNewPersonClick() {
        this.$refs['new-person'].showModal();
      }
    }
}
</script>

<style scoped lang="scss">
@keyframes display{
    0% { height: 0%; opacity: 0;}
    60% {opacity: 100;}
    100% { height: 20%;opacity: 100;}
}

@-webkit-keyframes display{
    0% { height: 0%;opacity: 0; }
    60% {opacity: 100;}
    100% { height: 20%;}
}
ion-footer{
    opacity: 0;
   -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;

    ion-toolbar {
    --background:#EFEFF2;
        div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 32px;
          h3 {
            margin-bottom: 18px;
            color: rgba(24, 31, 54, 1);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
    }
}
</style>