
export default {
  data() {
      return {
        componentKey: 0,
      };
    },
     methods: {
      incrementComponentKeyCounter() {
        this.componentKey++;
      }
    },
};