<template>
  <div class="row" @click="$emit('click', item)" :data-cy="`person-${item.id}`">
    <div class="info">
      <img
        v-if="item.avatar_image && item.avatar_image.length"
        class="pic"
        :src="item.avatar_image"
      />
      <template v-else>
        <div
          class="pic default"
          :style="{ backgroundColor: item.avatar_color }"
        >
          {{ item.first_name.substring(0, 1) }}{{ item.last_name.substring(0, 1) }}
        </div>
      </template>
      <h3>{{ item ? fullName : '' }}</h3>
    </div>
    <Arrow />
  </div>

  <template v-if="showModal">
    <Modal @close="showModal = false" @submit="checkinAttendee">
      <template #content class="modal">
        <img
          v-if="item.avatar_image && item.avatar_image.length"
          class="pic"
          :src="item.avatar_image"
        />
        <template v-else>
          <div class="pic default">
            {{ item.first_name.substring(0, 1) }}{{ item.last_name.substring(0, 1) }}
          </div>
        </template>

        <h2>{{ fullName }}</h2>

        <SelectInput
          v-model="classroom"
          label="Classroom"
          :options="options"
          class="select"
        />
      </template>

      <template #button="{ submit }">
        <Button
          text="Check In"
          :disabled="!classroom || processing"
          data-cy="checkin-person"
          :loading="processing"
          @click="submit"
        />
      </template>
    </Modal>
  </template>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import Modal from '@/components/shared/modals/Modal';
  import Arrow from '@/components/shared/icons/Arrow';
  import SelectInput from '@/components/shared/inputs/SelectInput';
  import Button from '@/components/shared/buttons/Button';

  export default {
    name: 'PersonItem',

    components: {
      Arrow,
      Modal,
      SelectInput,
      Button,
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
    },

    emits: ['click'],

    data() {
      return {
        showModal: false,
        classroom: null,
        options: ['One', 'Two', 'Three'],
        processing: false,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        service: (state) => state.services.current,
        checkinSession: (state) => state.checkin.session,
        attendees: (state) => state.checkin.attendees,
      }),

      checkedIn() {
        return !!this.attendees.find((p) => p.id === this.item.id);
      },

      fullName() {
        return `${this.item.first_name} ${this.item.last_name}`;
      },

      sessionStart() {
        if (!this.service) {
          return;
        }

        const date = this.$dayjs(this.service.service_date);
        const [hour, minutes] = this.service.start_time.split(':');

        return date.hour(hour).minute(minutes);
      },

      sessionFinish() {
        if (!this.service) {
          return;
        }

        return this.$dayjs(this.service.service_date).endOf('day');
      },

      serviceIsToday() {
        return this.$dayjs().isSame(this.service.service_date, 'day');
      },
    },

    methods: {
      ...mapActions({
        startSession: 'checkin/startSession',
        checkin: 'checkin/checkin',
      }),

      ...mapMutations({
        addAttendee: 'checkin/ADD_ATTENDEE',
      }),

      toggleModal() {
        if (this.checkedIn) {
          return;
        }

        this.showModal = true;
      },

      async checkinAttendee() {
        if (!this.service) {
          this.showToast('No event selected yet.', 'danger');
          return;
        }

        if (!this.serviceIsToday) {
          this.showToast(
            'The selected event is not scheduled for today.',
            'danger'
          );
          return;
        }

        if (this.checkedIn) {
          this.showToast('This person is already checked-in.', 'danger');
          return;
        }

        this.processing = true;

        try {
          if (!this.checkinSession) {
            await this.startSession({
              organizationId: this.user.currentOrganizationId,
              serviceId: this.service.id,
              start: this.sessionStart,
              finish: this.sessionFinish,
            });
          }

          await this.checkin({
            organizationId: this.user.currentOrganizationId,
            serviceId: this.service.id,
            personId: this.item.id,
          });

          this.addAttendee({
            ...this.item,
            classroom: this.classroom,
          });

          this.showModal = false;
          this.$router.push('/checkin/success');
        } catch (error) {
          this.showToast('Something went wrong,', 'danger');
        } finally {
          this.processing = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #181f36;
    width: 750px;
    height: 86px;
    left: 0px;
    top: 0px;
    padding-inline: 24px;
    background: #ffffff;
    margin-bottom: 20px;
    border: 1px solid rgba(80, 95, 115, 0.2);
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;

    .info {
      display: flex;
      align-items: center;

      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0%;
        margin-inline: 16px;
      }

      .pic {
        width: 48px;
        height: 48px;
        border-radius: 50%;

        &.default {
          background-color: #181f36;
          color: white;
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      span {
        color: #181f36;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
      }
    }
  }

  .modal {
    .pic {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      &.default {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #181f36;
        color: white;
        font-size: 42px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #181f36;
      margin-bottom: 38px;
    }

    .select {
      margin-bottom: 32px;
    }
  }
    @media only screen and (orientation: portrait) {
  .row {
   width: 710px;
  }
}
</style>
