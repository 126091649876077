<template>
  <div v-if="showNewPersonModal">
  
    <Modal @close="showNewPersonModal = false">
      <template #content>
        <NewPersonForm @created="handleNewPerson" />
      </template>

      <template #button>
        <span></span>
      </template>
    </Modal>
  
  </div>
</template>

<script>
  import NewPersonForm from './NewPersonForm.vue';
  import Modal from '@/components/shared/modals/Modal.vue';

  export default {
    components: {
      Modal,
      NewPersonForm,
    },

    emits: ['created'],

    data() {
      return {
        showNewPersonModal: false,
      };
    },

    methods: {
      handleNewPerson() {
        this.showNewPersonModal = false;
        this.$emit('created');
      },

      showModal() {
        this.showNewPersonModal = true;
      }
    },
  };
</script>

<style scoped lang="scss">
  ion-content {
    --background: white;

    h3 {
      margin-top: 30px;
      margin-bottom: 18px;
      color: rgba(24, 31, 54, 1);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
</style>
