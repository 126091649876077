<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="spinner-container"  v-if="singleCheckInLoading">
        <ion-spinner name="lines" color="primary" class="spinner"></ion-spinner>
      </div>
      <div class="container">
        <div class="searchBox" :class="show ? '' : 'bounce-enter-active'">
          <transition name="title">
            <div v-show="show">
              <h1>Check-In</h1>
              <p>{{ service.name }} @ {{ service.start_time }}</p>
              <span class="subtitle">Search for the person or household that you would like to check-in.</span>
            </div>
          </transition>

          <div class="search">
            <SearchInput
              id="searchInput"
              :initial-value="query"
              placeholder="Search by name and household"
              @focus="playAnimation()"
              @input="onSearchInput"
            />
          </div>
        </div>

        <div
          v-if="!show && query.length"
          class="resultBox"
          :class="show ? '' : 'result-enter-active'"
        >
          <HouseholdList
            :items="households"
            :show-result="showResult"
            :loading="loading"
            @select="onHouseholdSelect"
          />

          <PeopleList :items="people" :loading="loading" @select="onPersonSelect" />
        </div>
      </div>
      <SearchFooter v-if="!show" @created="fetchData"/>
    </ion-content>
  </ion-page>
</template>

<script>
  import { IonContent, IonPage, alertController, toastController } from '@ionic/vue';
  import { mapState, mapActions, mapMutations } from 'vuex';
  import { debounce } from 'lodash';

  import ViewMixin from '@/mixins/views';

  import SearchInput from '@/components/shared/inputs/SearchInput';
  import HouseholdList from '@/components/search/lists/HouseholdList';
  import PeopleList from '@/components/search/lists/PeopleList';
  import SearchFooter from '@/components/search/SearchFooter';

  export default {
    components: {
      IonContent,
      IonPage,
      SearchInput,
      HouseholdList,
      PeopleList,
      SearchFooter,
    },

    mixins: [ViewMixin],

    data() {
      return {
        show: true,
        showNewPersonModal: false,
        query: '',
        loading: false,
        singleCheckInLoading: false
      };
    },
    ionViewDidEnter() {
      this.incrementComponentKeyCounter();
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        service: (state) => state.services.current,
        session: (state) => state.checkin.session,
        location: (state) => state.locations.current,
        households: (state) => state.households.items,
        householdQuery: (state) => state.households.query,
        people: (state) => state.people.items,
        attendees: (state) => state.checkin.attendees,
        checkinSession: (state) => state.checkin.session,
        pendingAttendees: (state) => state.checkin.pendingAttendees,
      }),

      sessionStart() {
        if (!this.service) {
          return;
        }

        const date = this.$dayjs(this.service.service_date);
        const [hour, minutes] = this.service.start_time.split(':');

        return date.hour(hour).minute(minutes);
      },

      sessionFinish() {
        if (!this.service) {
          return;
        }

        return this.$dayjs(this.service.service_date).endOf('day');
      },

      serviceInformation() {
        return this.service
          ? `${this.service.name} @ ${this.service.start_time}`
          : 'Loading...';
      },
    },

    watch: {
      query(query) {
        if (query.length) {
          return;
        }

        this.setHouseholds([]);
        this.setPeople([]);
      },
    },

    async ionViewWillEnter() {
      this.query = this.householdQuery;

      if (!this.location) {
        this.$router.push({ name: 'Locations' });
      } else if (!this.service) {
        this.$router.push({
          name: 'LocationServices',
          params: { id: this.location.id },
        });
      }

      if (!this.session) {
        const session = await this.fetchSession({
          organizationId: this.user.currentOrganizationId,
          serviceId: this.service.id,
        });

        if (!session) {
          return;
        }

        if (session.closed) {
          return;
        }

        this.fetchSessionAttendees({
          organizationId: this.user.currentOrganizationId,
          sessionId: this.session.id,
        });
      }
    },

    methods: {
      ...mapActions({
        getHouseholds: 'households/get',
        getPeople: 'people/get',
        fetchSession: 'checkin/fetchSession',
        fetchSessionAttendees: 'checkin/fetchSessionAttendees',
        startSession: 'checkin/startSession',
        checkinAllPending: 'checkin/checkinAllPending',
      }),

      ...mapMutations({
        setHouseholds: 'households/SET_ITEMS',
        setPeople: 'people/SET_ITEMS',
        addAttendee: 'checkin/ADD_PENDING_ATTENDEE',
        moveToAttendees: 'checkin/MOVE_TO_ATTENDEES',
      }),

      async fetchData() {
        await this.getHouseholds({
          organizationId: this.user.currentOrganizationId,
          householdFilter: this.query,
        });

        await this.getPeople({
          organizationId: this.user.currentOrganizationId,
          peopleFilter: this.query,
        });
      },

      playAnimation() {
        this.show = false;
      },

      openPeopleFormModal() {
        this.showNewPersonModal = true;
      },

      send() {
        this.show = true;
        this.showResult = false;
      },

      onHouseholdSelect(item) {
        this.$router.push({
          name: 'LocationServiceSearchHousehold',
          params: {
            locationId: this.location.id,
            serviceId: this.service.id,
            householdId: item.id,
          },
        });
      },

      onPersonSelect(person) {
        if (!person.households.length) {
          if(this.attendees.find((p) => p.id === person.id)) {
            return this.showToast(
              'This person is already checked in',
              'danger'
            );
          } 

          return this.checkinPerson(person);
        }

        this.$router.push({
          name: 'LocationServiceSearchHousehold',
          params: {
            locationId: this.location.id,
            serviceId: this.service.id,
            householdId: person.households[0].id,
          },
        });
      },

      async checkinPerson(person) {
        const alert = await alertController
          .create({
            header: 'Check In',
            message: `Are you sure you want to checkin ${person.first_name}?`,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'Check in',
                cssClass: 'confirmSingleCheckIn',
                handler: () => {
                  this.handlePersonCheckIn(person);
                },
              },
            ],
          });
        return alert.present();
      },

      async handlePersonCheckIn(person) {
        this.addAttendee({
          ...person,
          school_grade: person.school_grade,
        });
        await this.doCheckin();
      },

      async doCheckin() {
        if (!this.service) {
          this.showToast('No event selected yet.', 'danger');
          return;
        }

        if (!this.$dayjs().isSame(this.service.service_date, 'day')) {
          this.showToast(
            'The selected event is not scheduled for today.',
            'danger'
          );
          return;
        }

        if (!this.pendingAttendees.length) {
          this.showToast('No attendees selected yet.', 'danger');
          return;
        }

        this.singleCheckInLoading = true;

        try {
          if (!this.checkinSession) {
            await this.startSession({
              organizationId: this.user.currentOrganizationId,
              serviceId: this.service.id,
              start: this.sessionStart,
              finish: this.sessionFinish,
            });
          }

          await this.checkinAllPending({
            organizationId: this.user.currentOrganizationId,
            serviceId: this.service.id,
          });

          this.$router.push('/checkin/success');
        } finally {
          this.singleCheckInLoading = false;
          if (this.pendingAttendees.length) {
            this.showToast('Some persons were not checked in.', 'danger');
          }
        }
      },

      onSearchInput: debounce(async function (event) {
        try {
          this.loading = true;
          this.query = event.target.value;

          await this.fetchData();
        } catch (error) {
          const toast = await toastController.create({
            message: 'Error while searching people and households',
            duration: 2000,
          });
          toast.present();
        }finally {
          this.loading = false;
        }
      }, 500),
    },
  };
</script>

<style scoped lang="scss">
  .title-enter-active,
  .title-leave-active {
    transition: opacity 0.5s;
  }
  .title-enter, .title-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @keyframes bounce {
    0% {
      height: 250px;
    }
    50% {
      height: 250px;
    }
    100% {
      height: 100px;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ion-content {
    .spinner-container {
      height: 100vh;
      width: 100%;
      position: fixed !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1000000;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
    }

    --offset-bottom: 22px!important;
    .container {
      h1 {
        margin-top: 80px;
      }

      p {
        font-size: 18px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 12px;
        color: var(--cosmos-500);
      }

      .subtitle {
        margin-bottom: 36px;
      }
      .bounce-enter-active {
        animation: bounce 0.5s 0.4s forwards;
        position: relative;
        display: flex;
        justify-content: center;
      }
      .bounce-leave-active {
        animation: bounce 0.5s reverse forwards;
      }

      .result-enter-active {
        opacity: 0;
        -webkit-animation: 1s ease 0s normal forwards 1 fadein;
        animation: 1s ease 0s normal forwards 1 fadein;
      }

      .searchBox {
        z-index: 100000;
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-bottom: 30px;
        position: relative;
        .search{
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          bottom: -40%;
          left: 0%;
        }
      }
      .resultBox {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 100%;
      }
    }
  }
</style>
