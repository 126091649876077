<template>
  <div class="inputBox">
    <ion-searchbar :placeholder="placeholder" :value="initialValue" @ionFocus="$emit('focus')" @ionInput="updateInput" @ionClear="updateInput" />
  </div>
</template>

<script>
  import { IonSearchbar } from '@ionic/vue';
  export default {
    name: 'SearchInput',

    components: {
      IonSearchbar,
    },

    props: {
      placeholder: { type: String },
      initialValue: { type: String, default: '' }
    },

    methods:{
      updateInput(event) {
        this.$emit('input', event);
      }
    }
  };
</script>
<style scoped lang="scss">
  ion-searchbar {
    --color: #212a4a;
    --background: white !important;
    --border-radius: 250px;
    --placeholder-color: #212a4a;
    --placeholder-font-weight: 500;
    --box-shadow: none;
    text-align: left;
    width: 750px;
    height: 60px;
    padding: 0px;
  }
   .searchbar-clear-button.sc-ion-searchbar-md{
      right: 20px !important;
   }
  @media (max-width: 768px){
    ion-searchbar {
      width: 720px;
    }
  }
</style>
