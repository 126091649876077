<template>
  <div class="household-select">
    <ion-item class="ion-no-padding" lines="none">
      <ion-label>Household</ion-label>
    </ion-item>

    <ion-item class="ion-no-padding" lines="none">
      <v-select
        v-model="selectedHousehold"
        :options="households"
        label="name"
        placeholder="Search Household"
        :class="{ hidden: !households.length }"
        @search="searchHousehold"
      >
        <template #option="option">
          <div class="household-option">
            <div class="image">
              <template
                v-if="option.avatar_image && option.avatar_image.length"
              >
                <img :src="option.avatar_image" />
              </template>

              <template v-else>
                <span>
                  {{ option.name.substring(1, 0) }}
                </span>
              </template>
            </div>
            <div class="details">
              <h3>{{ option.name }}</h3>
              <p>
                {{
                  option.members.map((member) => member.first_name).join(', ')
                }}
              </p>
            </div>
          </div>
        </template>
      </v-select>
    </ion-item>
  </div>
</template>

<script>
  import { IonItem, IonLabel } from '@ionic/vue';
  import vSelect from 'vue-select';
  import { mapState } from 'vuex';
  import { debounce } from 'lodash';
  import { people as peopleApi } from '@/api';

  export default {
    name: 'SearchableHouseholdInput',

    components: {
      IonItem,
      IonLabel,
      vSelect,
    },

    props: {
      modelValue: {
        type: Object,
        default: null,
      },
    },

    emits: ['update:modelValue'],

    data() {
      return {
        searchResults: [],
        selectedHousehold: null,
        query: '',
        households: [],
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),
    },

    watch: {
      selectedHousehold(selectedHousehold) {
        if (!selectedHousehold) {
          this.households = [];
        }

        this.$emit('update:modelValue', selectedHousehold);
      },
    },

    mounted() {
      if (this.modelValue) {
        this.selectedHousehold = this.modelValue;
      }
    },

    methods: {
      async fetchHouseholds(query) {
        try {
          const { data } = await peopleApi.get(
            `/organizations/${this.user.currentOrganizationId}/households?search=${query}`
          );

          this.households = data.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      searchHousehold: debounce(async function (query, loading) {
        if (!query.length) {
          return;
        }

        loading(true);

        await this.fetchHouseholds(query);

        loading(false);
      }, 500),
    },
  };
</script>

<style lang="scss">
  .household-option {
    display: flex;

    .image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      span {
        width: 100%;
        height: 100%;
        background-color: #212a4a;
        color: white;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
    }

    .details {
      margin-left: 16px;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #212a4a;
      }

      p {
        margin-top: 4px;
        font-size: 14px;
        color: #6c7e99;
      }
    }
  }

  .household-select {
    ion-item {
      width: 600px;
      position: relative;
      overflow: visible;

      ion-label {
        margin: 0px;
        margin-bottom: 0px !important;
        margin-left: 10px !important;
        color: #212a4a;
        font-size: 17px;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        text-align: left;
      }
    }

    .v-select {
      width: 600px;

      .vs__open-indicator {
        display: none;
      }

      .vs__no-options {
        text-align: center;
      }

      .vs__dropdown-toggle {
        position: relative;
        background-color: white;

        .vs__search {
          width: 100%;
          position: relative;
          color: #212a4a;
          background: transparent;
          font-size: 14px;
          border-radius: 24px;
          padding-left: 32px;
          text-align: left;
          height: 53px;
          border: 1px solid #e9e9e9;
          z-index: 10;

          &:focus {
            outline: none;
          }
        }

        .vs__selected {
          position: absolute;
          z-index: 12;
          top: 0;
          bottom: 0;
          left: 0;
          padding-left: 32px;
          border-radius: 24px;
          display: flex;
          align-items: center;
        }

        .vs__actions {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin-right: 12px;
          display: flex;
          align-items: center;
          z-index: 11;

          .vs__clear {
            padding: 6px;
            background-color: transparent;
          }

          .vs__spinner,
          .vs__spinner:after {
            border-radius: 50%;
            width: 5em;
            height: 5em;
          }

          .vs__spinner {
            align-self: center;
            font-size: 5px;
            text-indent: -9999em;
            overflow: hidden;
            border: 0.9em solid hsla(0, 0%, 39.2%, 0.1);
            border-left-color: rgba(60, 60, 60, 0.45);
            transform: translateZ(0);
            -webkit-animation: vSelectSpinner 1.1s linear infinite;
            animation: vSelectSpinner 1.1s linear infinite;
            transition: opacity 0.1s;
          }
        }
      }

      .vs__dropdown-menu {
        position: absolute;
        bottom: 43px;
        padding: 0;
        width: 600px;
        background-color: white;
        z-index: 10;
        border-radius: 12px;
        border: 1px solid #e9e9e9;
        max-height: 200px;
        padding: 12px 0;
        overflow-y: scroll;
        list-style: none;

        .vs__dropdown-option {
          line-height: 1.42857143;
          display: block;
          padding: 6px 32px;
          clear: both;
          color: #333;
          white-space: nowrap;
          cursor: pointer;
        }

        .vs__dropdown-option--highlight {
          background: rgba(73, 208, 183, 0.075);
        }
      }

      &.hidden {
        .vs__dropdown-menu {
          display: none;
        }
      }

      &.vs--open,
      &.vs--loading {
        .vs__selected {
          opacity: 0.4;
        }
      }

      &.vs--searching {
        .vs__selected {
          display: none;
        }
      }
    }
  }

  @-webkit-keyframes vSelectSpinner {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes vSelectSpinner {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
</style>
